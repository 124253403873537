import "./Philosophy.css";

function Philosophy() {
    return (
        <div id="philosophy" className="content-container">
            <h2>My Teaching Philosophy</h2>
            <p>
                Every student has the potential to thrive. That fundamental belief is why I love my life
                as an educator. I use the classroom to empower students, teaching them to embrace challenges
                and learn from mistakes. Understanding failure as a learning experience and not a life sentence
                is vitally important for personal and professional growth.
            </p>
            <p>
                Running an animation studio for a decade deeply reinforces the importance of balancing real-world
                application with academic theory. The classroom as a pivotal tool to helping students recognize
                the challenges and opportunities that arise within a professional environment. Bridging this gap
                is vital to a successful educational experience.
            </p>
            <p>
                I see accessibility and responsiveness as the core of this commitment: education is a collaborative
                journey where every voice matters. To this end I encourage active participation and accountability,
                recognizing that each student contributes uniquely to the learning experience. As an educator, the
                greatest gift I can give a student is the toolset to unlock their own potential.
            </p>
        </div>
    );
}

export default Philosophy;