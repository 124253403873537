import "./Welcome.css";

function Welcome() {
    return (
        <div id="welcome">
            <div className="row">
                <div className="column content-container-column">
                <div className="wrapper"><img src="aaron_1.png" /></div>
                      </div>
                <div className="column content-container-column-end">
                <h1>Hi, I'm Aaron Powell!</h1>
                    <p>I'm a creative professional and Visiting Lecturer at the Rochester Institute of Technology (RIT). Specializing in lighting, rendering, compositing, and pipeline development, I bring a decade of hands-on industry experience to the classroom.</p>
             
                    
                </div>
            </div>
            
        </div>
    );
}

export default Welcome;