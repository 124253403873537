import logo from './logo.svg';
import './App.css';
import { Welcome, AboutMe, Philosophy, Gallery } from './components';

function App() {
  const studentGallery = [
    {url: "4kDreamDarker.png", thumbnail: ""},
    {url: "Blaker_StillLife.jpg", thumbnail: ""},
    {url: "HarrisonRender03.jpg", thumbnail: ""},
    {url: "Kemeny_StillLife.png", thumbnail: ""},
    {url: "Moran_HQ_InteriorScene.jpg", thumbnail: ""},
    {url: "Quigley_StillLife.jpg", thumbnail: ""},
    {url: "Scott_Still_Life.jpg", thumbnail: ""},
    {url: "Vu_Interior2.jpg", thumbnail: ""},
  ];
  const professionalGallery = [
    {
      url: "https://player.vimeo.com/video/497043372?h=ff034853a6&title=0&byline=0&portrait=0",
      thumbnail: "https://i.vimeocdn.com/video/1028624281-e9cabda7b81f29457ed7f079d51f3323d216703f0d2de4864485eb927d2d6eaf-d?mw=1920&mh=1080&q=70",
      comments: 
        <div>
          <h2>Studio Show Reel</h2>
          <p>This is Luna Digital's current show reel. It's a quick look at some of the best work I've done at the studio over the past few years, up until about 2022.</p>
        </div>
    },
    {
      url: "https://player.vimeo.com/video/381367779?h=5d300a97b7&title=0&byline=0&portrait=0",
      thumbnail: "https://i.vimeocdn.com/video/842072885-cffef64ecd7630489936c35e5b1bb5ebc1c77502111edc4e08cca18de915c1c7-d?mw=1920&mh=1080&q=70",
      comments:
        <div>
          <h2>Geri's Christmas</h2>
          <p>When work dried up at the end of 2019 I buckled down for three weeks and created this Christmas animation. Geri the robot welcomes the holiday season with some excitement, frustration, and a few chuckles along the way.</p>
          <h3>Additional Links</h3>
          <p>
            <a href="https://vimeo.com/382962743" target='_blank'>Animatic, behind-the-scenes</a><br />
            <a href="https://vimeo.com/383183257" target='_blank'>Layout, behind-the-scenes</a>
          </p>
        </div>
    },
    {
      url: "https://player.vimeo.com/video/412966939?h=8db7218843&title=0&byline=0&portrait=0",
      thumbnail: "https://i.vimeocdn.com/video/885541378-8486dadd1de52422c8b471183241b231a8d7b7b2720af21a7aff44e394d2a158-d?mw=1920&mh=1080&q=70",
      comments:
        <div>
          <h2>The Micro Instrument Museum of History</h2>
          <p><i>Geri's Christmas</i> caught the attention of Micro Instrument Corp, a family-owned manufacturing company right here in Rochester looking to increase their presence on social media. Micro has worked on some amazing projects over the past 75 years, including work for NASA and the Mars rovers, but they've never known how to brag about themselves.</p>
          <p>Now, they can. Take a walk through the Micro Instrument Museum of History with Marv and Mobi!</p>
          <h3>Additional Links</h3>
          <p>
            <a href="https://vimeo.com/412589306" target='_blank'>Behind-the-scenes: Stages of Animation</a>
          </p>
        </div>
    },
    {
      url: "https://player.vimeo.com/video/893549809?h=2554b23c88&title=0&byline=0&portrait=0",
      thumbnail: "https://i.vimeocdn.com/video/1767341084-73d8d3b6bfb33fd6686d4eb7c52c484fdf5e58b43498371468ac0631e4a104e8-d?mw=1300&mh=731&q=70",
      comments:
        <div>
          <h2>Bridgestone &mdash; A Layered Journey</h2>
          <p>In 2020, Bridgestone announced a global campaign to demonstrate how they implement sustainable, responsible rubber-procuring practices for their tires. I worked with Luxurious Animals, one of my longest-running creative partners, to deliver this animation for Bridgestone and their agency-of-record.</p>
          <p>I was the sole 3D artist and was responsible for all 3D visuals and animation. Luxurious Animals added some minor editing, color, and motion graphics on top of the 3D work. To complete the project in time, we had to build a render farm on AWS Deadline and move the entire creative pipeline to render in Arnold, while we developed and delivered the animation in 6 weeks.</p>
          <h3>Additional Links</h3>
          <p>
            <a href="https://www.bridgestoneamericas.com/en/press-release-details.en.2020.layered-journey-sustainability-campaign" target='_blank'>Campaign Press Release (Bridgestone)</a><br />
            <a href="https://lunadigital.medium.com/luna-digital-s-arnold-b40578dac5e7" target='_blank'>Behind-the-scenes: Moving to Arnold</a>
          </p>
        </div>
    },
    {
      url: `${window.location.href}ticketmaster.html`,
      thumbnail: "images/ticketmaster.png",
      comments:
        <div>
          <h2>Ticketmaster GIPHY Library</h2>
          <p>Premiere Lacrosse League/Ticketmaster hired Luna in 2022 to design and develop a library of sharable social GIFs for Ticketmaster International's GIPHY library. We were given a list of targets to hit, then released with a lot of creative freedom to experiment and play with ideas.</p>
          <p>I was responsible for design, 3D modeling, animation, and shading, while another artist worked on design and concepts and other prep work.</p>
        </div>
    },
    {
      url: "metallica.jpg",
      thumbnail: "",
      comments:
        <div>
          <h2>Metallica Black Box</h2>
          <p>I worked with the teams at Inveniem and Metallica to design a digital, 20-ft Metallica-branded shipping container and composited the 3D asset into a series of historical photos of the band. It was designed to embody the spirit of the campaign – your ticket to all things “Metallica”.</p>
          <p>The Black Box website showcases “[Metallica's] favorite, most significant, and personally meaningful artifacts in collaboration with our friends at Inveniem and Definitive Authentic.” (metallica.com) Fans can purchase one-of-a-kind memorabilia and explore over 40 years of rock history! The renders were so convincing that an industry news article captioned one of my renders as “the real-life ‘black box' that accompanies the band during their world tours”. (nme.com)</p>
          <h3>Additional Links</h3>
          <p>
            <a href="https://metallicablackbox.com/" target='_blank'>Metallica Black Box Website</a><br />
            <a href="https://www.metallica.com/news/2021-11-16-the-metallica-black-box-opens-up.html" target='_blank'>"The Metallica Black Box Opens Up"</a><br />
            <a href="https://www.nme.com/news/music/metallica-unveil-career-spanning-black-box-project-featuring-rare-content-3103435" target='_blank'>NME Article</a>
          </p>
        </div>
    },
    {
      url: "https://player.vimeo.com/video/700266087?h=3474f90cd1",
      thumbnail: "https://i.vimeocdn.com/video/1588365766-095dc5be528525570cc83e7bcc4841289d2efeb0207192d25156fa139b0bf3b4-d?mw=1920&mh=1080&q=70",
      comments:
        <div>
          <h2>What Makes Karma Water Different?</h2>
          <p>I partnered with local creative powerhouses Mason Digital and Captivate Media to produce this 30-second spot for Karma Water. It was one of the first times we used Luna's Arnold for Blender plugin throughout the entire production. We produced a series of alternative formats for social and streaming platforms in addition to the main HD deliverable.</p>
        </div>
    },
    {
      url: `${window.location.href}sundaepc.html`,
      thumbnail: "images/sundaepc.png",
      comments:
        <div>
          <h2>SundaePC Product Renders</h2>
          <p>SundaePC is a mini PC designed for small or shared spaces. I was hired to develop a series of visuals for advertising, including a short sizzle animation, video edits for social, and animated GIFs. I edited the social videos with footage shot by another vendor, but there's one product shot of the SundaePC that wasn't captured on-set so we filmed "pick-up shots" at the Luna Digital offices and digitally inserted the PC ourselves.</p>
          <p>Some interesting things to note:</p>
          <ul>
            <li>The office GIF is completely 3D, but the kitchen and living room GIFs are a mix of real footage and digital products and props.</li>
            <li>We started the project rendering with Gaffer and Arnold, but many assets were converted to BtoA, Luna's Arnold for Blender plugin.</li>
          </ul>
          <h3>Additional Links</h3>
          <p>
            <a href="https://sundaepc.com/" target='_blank'>SundaePC.com</a>
          </p>
        </div>
    },
  ];

  return (
    <div className="App">
      <Welcome />
      <AboutMe />
      <Philosophy />
      <Gallery title="Student Work" content={studentGallery} />
      <Gallery title="Professional Work" content={professionalGallery} />
      <div className="color-bg" style={{width: `100%`, height: `1em`}}></div>
    </div>
  );
}

export default App;
