function AboutMe() {
    return (
        <div id="about" className="content-container color-bg">
            <h2>About Me</h2>
            <p>I'm a dedicated professional and educator with a unique combination of creative savvy
                and technical expertise. For a decade, I forged my own path by founding and managing
                a small animation studio called <a target="_blank" href="https://www.lunadigital.tv">Luna Digital</a>, where I partnered with brands like Adobe,
                Dolby, Bridgestone, and more. Two years ago, I made a deliberate shift into education,
                guided by a teaching philosophy grounded in the belief that every student has the potential
                for success regardless of background or experience. This transition from entrepreneur to
                educator gives me an opportunity to apply practical, real-world experience in the classroom.</p>
        </div>
    );
}

export default AboutMe;