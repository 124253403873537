import { useEffect, useState } from "react";
import "./Gallery.css";

function validateUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (error) {
        return false;
    }

    return true;
}

function Gallery({title, content}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [overlayActive, setOverlayActive] = useState(false);
    const [commentsAvailable, setCommentsAvailable] = useState(false); 
    const [commentsVisible, setCommentsVisible] = useState(false);

    const identifier = title.replace(/\s+/g, '-').toLowerCase();
    
    const showImage = (index) => {
        setActiveIndex(index);
        setOverlayActive(true);
    };

    if ("comments" in content[activeIndex] && !commentsAvailable) {
        setCommentsAvailable(true);
        setCommentsVisible(true);
    }

    const overlay = [
        <div id={`${identifier}-gallery-spotlight`} className="gallery-container">
            <div className="row">
            <div className="gallery-spotlight column">
                <img id="expand-btn" src={"expand.png"} onClick={() => { setCommentsVisible(!commentsVisible); }} />
                <img id="close-btn" src={"close.png"} onClick={() => { setOverlayActive(false); }} />
                
                <div id="featured">
                    <iframe src="https://player.vimeo.com/video/497043372?h=ff034853a6&title=0&byline=0&portrait=0" width="1920" height="1080" frameborder="0" scrolling="no" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <img src={`images/${content[activeIndex]}.url`} />
                </div>

                <img id="next" src={"next.png"} onClick={() => {
                    if (activeIndex < content.length - 1) setActiveIndex(activeIndex + 1);
                    else setActiveIndex(0);
                }} />
                <img id="back" src={"back.png"} onClick={() => {
                    if (activeIndex > 0) setActiveIndex(activeIndex - 1);
                    else setActiveIndex(content.length - 1);
                }} />
            </div>
            <div className="gallery-item-comments column">
                {content[activeIndex].comments}
            </div>
            </div>
        </div>
    ];

    const imageGallery = [];
    content.forEach((image, index) => {
        let imgSrc = (validateUrl(image.url)) ? image.thumbnail : `images/${image.url}`;
        imageGallery.push(
            <div className="gallery-item">
                <img src={imgSrc} onClick={() => { showImage(index); }} />
            </div>
        );
    });

    useEffect(() => {
        const image = document.querySelector(`#${identifier}-gallery-spotlight #featured img`);
        const iframe = document.querySelector(`#${identifier}-gallery-spotlight #featured iframe`);

        if (validateUrl(content[activeIndex].url)) {
            image.style.display = "none";
            iframe.style.display = "block";
            iframe.src = `${content[activeIndex].url}`;
        } else {
            iframe.style.display = "none";
            image.style.display = "block";
            image.src = `images/${content[activeIndex].url}`;
        }

        const overlay = document.querySelector(`#${identifier}-gallery-spotlight`);
        if (overlayActive) overlay.style.display = "flex";
        else overlay.style.display = "none";

        const comments = document.querySelector(`#${identifier}-gallery-spotlight .gallery-item-comments`);
        const expandButton = document.querySelector(`#${identifier}-gallery-spotlight #expand-btn`);
        if (commentsAvailable) {
            expandButton.style.display = "block";

            if (commentsVisible) {
                comments.style.display = "flex";
                expandButton.src = "expand.png";
            }
            else {
                comments.style.display = "none";
                expandButton.src = "shrink.png";
            }
        } else {
            expandButton.style.display = "none";
        }
    });

    return (
        <div id={identifier} className="content-container no-vpadding">
            {overlay}
            <h2>{title}</h2>

            <div className="gallery">
                {imageGallery}
            </div>
        </div>
    );
}

export default Gallery;